// Include Sass Files
@import "inc/variables/colors.scss";
@import "inc/variables/fonts.scss";
@import "inc/variables/others.scss";

@import "inc/mixins/media-query.scss";
@import "inc/mixins/flex.scss";


@include for-phone-up{

    body.home-page{
        background: rgb(44,109,228);
        background: radial-gradient(circle, rgba(44,109,228,1) 0%, rgba(28,65,125,1) 100%);
        overflow-x: hidden;
    }

    #home{
        animation: kf-opacity;
        animation-duration: 3s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-delay: 0s;
        animation-play-state: initial;
        animation-fill-mode: forwards;
        overflow: hidden;

        .img-home-desk{
            display: none;
        }

        .img-mobo{
            position: relative;
        }

        .img-home-mob{
            display: block;
            position: absolute;
            width: 250px;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            right: -100px;
            bottom: 70px;
        }

        .content-home{

            .title-home{
                background-color: #5b84df;
                padding-top: 30px;
                padding-bottom: 30px;

                h1{
                    font-size: 35px;
                    color: #FFF;
                    width: 90%;
                    margin: 0 auto;
                    text-align: center;
                    font-family: "HelveticaNeueBold";
                }
            }

            .video-home{

                padding-top: 30px;
                padding-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                .video-container{
                    width: 90%;
                    max-width: 500px;

                    .videoWrapper {
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        height: 0;
                    }

                    .videoWrapper iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                
            }

            .btn-home{

                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .btn-start{
                    display: inline-block;
                    padding: 20px 80px 20px 40px;
                    background-color: #0FDE8E;
                    font-family: "HelveticaNeueBold";
                    font-size: 24px;
                    font-style: italic;
                    border-radius: 50px;
                    text-decoration: none;
                    cursor: pointer;
                    color: #C42AA3;

                    &:hover{
                        background-color: #5adfac;
                    }

                    &:active{
                        background-color: #5adfac;
                    }
                }

                .btn-info{
                    display: inline-block;
                    padding: 20px 20px 20px 20px;
                    background-color: #C42AA3;
                    font-family: "HelveticaNeueBold";
                    font-size: 24px;
                    font-style: italic;
                    border-radius: 50px;
                    text-decoration: none;
                    cursor: pointer;
                    color: #FFF;
                    margin-top: 20px;

                    &:hover{
                        background-color: #c05aaa;
                    }

                    &:active{
                        background-color: #c548aa;
                    }
                }

            }
            
            

        }

    }

}

@include for-tablet-up{

}

@include for-desktop-up{

    #home{

        .img-desk{
            position: relative;
        }

        .img-home-desk{
            display: block;
            position: absolute;
            right: 200px;
            top: 0px;
            width: 350px;
            z-index: 10;
        }

        .img-home-mob{
            display: none;
        }

        .content-home{

            .title-home{
                padding-top: 20px;
                padding-bottom: 40px;
                padding-left: 50px;

                h1{
                    position: relative;
                    left: 60px;
                    font-size: 45px;
                    width: 35%;
                    text-align: left;
                }
            }

            .video-home{
                padding-bottom: 0px;
                position: relative;
                height: 140px;
                margin-top: 230px;
                top: -50px;
                padding-left: 50px;
                background: linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(#5b84df,1) 50%, rgba(#5b84df,1) 100%);

                .video-container{
                    max-width: 600px;
                    position: relative;
                    top: -114px;
                }
                
            }

            .btn-home{

                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: -50px;
                padding-left: 50px;

                .btn-start{
                    padding: 20px 60px 20px 60px;
                    font-size: 30px;
                }

                .btn-info{
                    display: inline-block;
                    padding: 20px 30px 20px 30px;
                    background-color: #C42AA3;
                    font-family: "HelveticaNeueBold";
                    font-size: 26px;
                    font-style: italic;
                    border-radius: 50px;
                    text-decoration: none;
                    cursor: pointer;
                    color: #FFF;
                    margin-top: 20px;

                    &:hover{
                        background-color: #c05aaa;
                    }

                    &:active{
                        background-color: #c548aa;
                    }
                }

            }
            
            

        }

    }

}

@include for-tv-up{

    #home{

        .img-desk{
            position: relative;
        }

        .img-home-desk{
            display: block;
            position: absolute;
            right: 100px;
            top: 0px;
            width: 485px;
            z-index: 10;
        }

        .img-home-mob{
            display: none;
        }

        .content-home{

            .title-home{
                padding-top: 20px;
                padding-bottom: 40px;

                h1{
                    position: relative;
                    left: 250px;
                    font-size: 60px;
                    width: 35%;
                    text-align: left;
                }
            }

            .video-home{
                padding-bottom: 0px;
                position: relative;
                height: 180px;
                margin-top: 300px;
                top: -50px;
                background: linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(#5b84df,1) 50%, rgba(#5b84df,1) 100%);

                .video-container{
                    max-width: 800px;
                    position: relative;
                    top: -150px;
                    left: 160px;
                }
                
            }

            .btn-home{

                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: -30px;
                left: 140px;

                span{
                    padding: 20px 60px 20px 60px;
                    font-size: 40px;
                }

            }
            
            

        }

    }

}